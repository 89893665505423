import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/layout'
import PageBuilder from '../../components/common/PageBuilder/PageBuilder'
import PageIntro from '../../components/common/PageIntro'
import { Helmet } from 'react-helmet'

const Risk = ({ data: { strapiRisk } }) => {
	return (
		<Layout>
			<Helmet>
				<title>Delta Insurance | Risks | {strapiRisk.Title}</title>
				<meta
					name="description"
					content={strapiRisk.Paragraph ? strapiRisk.Paragraph : ''}
				/>
			</Helmet>
			<h1 class="visually-hidden">{strapiRisk.Title}</h1>
			<PageIntro
				title={strapiRisk.Title && strapiRisk.Title}
				summary={strapiRisk.Paragraph && strapiRisk.Paragraph}
				image={strapiRisk.HeroImage ? strapiRisk.HeroImage : undefined}
			/>
			{strapiRisk.PageBuilder && <PageBuilder data={strapiRisk.PageBuilder} />}
		</Layout>
	)
}

export default Risk

export const query = graphql`
	query RisksQuery($Slug: String!) {
		strapiRisk(Slug: { eq: $Slug }) {
			PageBuilder
			Paragraph
			Slug
			Title
			HeroImage {
				provider_metadata {
					public_id
				}
				formats {
					medium {
						url
					}
					small {
						url
					}
					thumbnail {
						url
					}
				}
			}
		}
	}
`
